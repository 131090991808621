@import url("./normalize.css");
@import url("./modal.css");

body {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  color: #fff;
  background-color: #000;
}

a {
  color: inherit;
  text-decoration: none;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  padding: 0 8px;
  box-sizing: border-box;
}

.container_wide {
  padding: 0;
}

.p-index {
}

.p-content__banner-bottom {
  margin-top: 24px;
}

.p-content__previews {
  margin-top: 32px;
}

.header-m {
  background-color: #0e0e0e;
}

.header-m__top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.header-m__menu {
  width: 48px;
  color: #ff9000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-m__logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.header-m__actions {
  display: flex;
}

.header-m__action {
  width: 48px;
  color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-m__items {
  display: flex;
}

.header-m__item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c6c6c6;
  height: 32px;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid #151515;
}

.networks {
  display: none;
}

.networks__items {
  display: flex;
  justify-content: center;
}

.networks__item {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 11px;
  color: #969696;
  height: 22px;
  text-transform: uppercase;
  padding: 0 24px;
}

.networks__item:hover {
  color: #c6c6c6;
}

.header {
  display: none;
  background-color: #0e0e0e;
}

.header__inner {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  height: 70px;
}

.header__left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header__menu {
  color: #ff9000;
}

.header__center {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 20px;
}

.header__search {
  position: relative;
}

.header__search-icon {
  position: absolute;
  top: 10px;
  left: 20px;
}

.header__search-field {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border: none;
  border-radius: 20px;
  padding-left: 50px;
  background-color: #252525;
  color: #fff;
}

.header__uploads {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #c6c6c6;
}

.header__right {
  display: flex;
  justify-content: end;
  color: #c6c6c6;
}

.menu {
  display: none;
  background-color: #0e0e0e;
}

.menu__items {
  display: flex;
}

.menu__item {
  position: relative;
  cursor: pointer;
  height: 36px;
  width: 100%;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 1px solid #252525;
  border-color: transparent;
}

.menu__item:hover {
  background-color: #191919;
  border-color: #252525;
}

.menu__item_active::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #f90;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.menu__item_accent {
  color: #000;
  background-color: #f90;
}

.menu__item_accent:hover {
  background-color: #ff9f0e;
  border-color: transparent;
}

.menu__item .fa-solid {
  margin-left: 6px;
}

.p-index__title {
  font-size: 18px;
  margin: 32px 0 16px;
}

.p-index__tags {
  margin-bottom: 16px;
}

.p-content__content {
  margin-top: 32px;
}

.tags__items {
  display: flex;
  align-items: center;
  gap: 6px;
  overflow: hidden;
  white-space: nowrap;
}

.tags__item {
  padding: 10px 15px;
  background-color: #151515;
  border-radius: 60px;
  border: 2px solid #212121;
  color: inherit;
  transition: background-color 0.5s cubic-bezier(0.05, 0, 0, 1);
}

.tags__item:hover {
  background-color: #212121;
}

.previews {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;
  column-gap: 12px;
}

.preview {
  color: #c6c6c6;
  position: relative;
}

.preview::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f90;
  display: block;
  height: 3px;
  width: 0;
}

.preview:hover::after {
  animation-name: preview-loader;
  animation-duration: 1s;
}

.preview__content {
  position: relative;
  display: block;
}

.preview__image {
  width: 100%;
}

.preview__time {
  position: absolute;
  right: 12px;
  bottom: 12px;
  font-size: 13px;
  color: #c6c6c6;
  padding: 2px 6px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  background-color: rgba(27, 27, 27, 0.8);
}

.preview__info {
  padding: 0 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #c6c6c6;
}

.preview__params {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  color: #767676;
}

.preview__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  margin-top: 8px;
}

.preview__channel:hover {
  color: #fff;
}

.preview__title:hover {
  color: #fff;
}

@keyframes preview-loader {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.banner {
  position: relative;
}

.banner::after {
  content: "Ad";
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 15px;
  right: 0;
  top: 0;
  background-color: #299cb2;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.35);
  border-radius: 3px;
  transition: 0.6s;
  padding: 0 4px;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 10px;
}

.banner_box {
  width: 300px;
  height: 250px;
}

.banner_bottom {
  width: 950px;
  max-width: 100%;
  height: 250px;
  margin: 0 auto;
}

.banner__image {
  width: 100%;
  height: 100%;
}

.previews__banner {
  grid-row-start: 3;
  justify-self: center;
}

.p-index__pagination {
  margin-top: 32px;
}

.p-index__banner-bottom {
  margin-top: 32px;
}

.pagination__items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 700;
  color: #c6c6c6;
}

.pagination__item {
  cursor: pointer;
  width: 52px;
  height: 55px;
  background-color: #151515;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.pagination__action {
  cursor: pointer;
  width: 117px;
  height: 55px;
  background-color: #151515;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.pagination__action_back {
  background-color: #0e0e0e;
  color: #2f2f2f;
  pointer-events: none;
}

.pagination__action_next {
  background-color: #f90;
  color: #000;
}

.pagination__action_next:hover {
  background-color: #ff9f0e;
}

.pagination__item:hover {
  background-color: #2f2f2f;
}

.footer {
  color: #969696;
  text-align: center;
  font-size: 14px;
  margin-top: 32px;
}

.footer__copyright {
  margin-top: 16px;
  padding: 12px 0;
  border-top: 1px solid #2f2f2f;
}

.footer__authors {
  padding-bottom: 12px;
}

.footer__author a {
  color: #f90;
}

/* Content */

.content {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
}

.content__player {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.content__player-video,
.content__player-meme {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.content__player-img {
  width: 100%;
}

.content__info {
  margin: 8px 0;
  padding: 0 8px;
}

.info__title {
  font-size: 17px;
  font-weight: 700;
}

.info__stats {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #969696;
  font-weight: 700;
  margin-top: 12px;
}

.info__stats-item {
  padding: 0 10px;
  border-right: 1px solid #9b9b9b;
}

.info__stats-item:first-child {
  padding-left: 0;
}

.info__stats-item:last-child {
  padding-right: 0;
  border-right: none;
}

.info__actions {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.info__action {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #cacaca;
  height: 60px;
  cursor: pointer;
}

.info__action:hover {
  color: #fff;
  box-shadow: 0px -2px 0px 0px #ff9000 inset;
}

.info__action-icon {
  font-size: 20px;
}

.info__action-text {
  font-size: 11px;
}

.info__channel {
  margin-top: 15px;
}

.info__channel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.info__channel-avatar {
  display: flex;
}

.info__channel-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.info__channel-name {
  display: flex;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 2px;
}

.info__channel-name .fa-circle-check {
  font-size: 14px;
  color: #3a88e9;
}

.info__channel-stats {
  font-size: 12px;
  color: #969696;
}

.info__controls {
  margin-top: 24px;
}

.info__control {
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  color: #000;
  background-color: #f90;
}

.info__control:hover {
  background-color: #ff9f0e;
}

.comments {
  padding: 0 8px;
  margin-top: 32px;
}

.comments__title {
  font-size: 19px;
  font-weight: 700;
  color: #cbcbcb;
  margin-bottom: 8px;
}

.comments__item {
  margin-bottom: 24px;
}

.comments__item:last-child {
  margin-bottom: 0;
}

.comments__avatar {
  display: flex;
  align-items: center;
}

.comments__avatar-img {
  border-radius: 50%;
  margin-right: 16px;
}

.comments__avatar-name {
  color: #ff9000;
  font-size: 16px;
  font-weight: 700;
}

.comments__avatar-date {
  font-size: 14px;
  color: #969696;
  margin-left: 8px;
}

.comments__text {
  margin-top: 10px;
  font-size: 15px;
  color: #c6c6c6;
}

@media (min-width: 1140px) {
  .container {
    padding: 0;
  }

  .header-m {
    display: none;
  }

  .networks {
    display: block;
  }

  .header {
    display: block;
  }

  .menu {
    display: block;
  }

  .p-index__tags {
    margin-bottom: 24px;
  }

  .previews.p-index__previews {
    grid-template-columns: repeat(4, 1fr);
  }

  .previews.p-content__previews {
    grid-template-columns: repeat(3, 1fr);
  }

  .previews__banner {
    grid-row-start: 1;
    grid-column-start: 4;
  }

  .content {
    grid-template-columns: 3fr 1fr;
  }
}
